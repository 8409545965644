import React, { useEffect, useState, Fragment } from 'react';
import { Card, CardBody, Table } from 'reactstrap';

import JobVisual from './JobVisual';

const ordersDataInitialState = [
  {
    id: 0,
    order_name: 'Order 1',
    op_effort_hours: 10,
    op_start: 2, // week number. TODO: Change name to reflect that. Check how it's used in the handlePointerMove method.
    op_delay: 2, // in weeks. TODO: Change name to reflect that. Check how it's used in the handlePointerMove method.
    specials_effort_hours: 4,
    specials_start: 5, // week number. TODO: Change name to reflect that. Check how it's used in the handlePointerMove method.
    specials_delay: 1 // in weeks. TODO: Change name to reflect that. Check how it's used in the handlePointerMove method.
  },
  {
    id: 1,
    order_name: 'Order 2',
    op_effort_hours: 2,
    op_start: 0,
    op_delay: 1,
    specials_effort_hours: 10,
    specials_start: 2,
    specials_delay: 1
  },
  {
    id: 2,
    order_name: 'Order 3',
    op_effort_hours: 7,
    op_start: 0,
    op_delay: 1,
    specials_effort_hours: 8,
    specials_start: 5,
    specials_delay: 1
  },
  {
    id: 3,
    order_name: 'Order 4',
    op_effort_hours: 6,
    op_start: 1,
    op_delay: 3,
    specials_effort_hours: 1,
    specials_start: 6,
    specials_delay: 1
  },
  {
    id: 4,
    order_name: 'Order 5',
    op_effort_hours: 6,
    op_start: 4,
    op_delay: 0,
    specials_effort_hours: 1,
    specials_start: 6,
    specials_delay: 0
  },
  {
    id: 5,
    order_name: 'Order 6',
    op_effort_hours: 6,
    op_start: 1,
    op_delay: 0,
    specials_effort_hours: 1,
    specials_start: 2,
    specials_delay: 0
  }
];

const buildTableHeaders = width => {
  const headers = [];
  for (let i = 1; i <= width; i++) {
    headers.push(`2021 w${i}`);
  }
  return headers;
};

const buildTableMatrix = (width, orders) => {
  const matrix = [];
  const matrixRow = [];
  for (let i = 0; i < width; i++) {
    matrixRow.push(null);
  }
  orders.map(order => {
    const newRow = [order.order_name, ...matrixRow];
    newRow[order.op_start + 1] = {
      order_id: order.id,
      type: 'op',
      effort_hours: order.op_effort_hours,
      start: order.op_start,
      delay: order.op_delay
    };
    newRow[order.specials_start + 1] = {
      order_id: order.id,
      type: 'specials',
      effort_hours: order.specials_effort_hours,
      start: order.specials_start,
      delay: order.specials_delay
    };
    matrix.push(newRow);
    return null;
  });
  return matrix;
};

const Test = ({ weeksToShow = 24, width = 75 }) => {
  const [tableGrid, setTableGrid] = useState([]);
  const [ordersData, setOrdersData] = useState(ordersDataInitialState);
  const [headerTitles, setHeaderTitles] = useState([]);

  const [isDragging, setIsDragging] = useState(false);
  const [draggingPosition, setDraggingPosition] = useState();
  const [activeJob, setActiveJob] = useState();
  const [startingPosition, setStartingPosition] = useState();
  const [currentPosition, setCurrentPosition] = useState(0);
  const [typeName, setTypeName] = useState('');
  const [paramName, setParamName] = useState('');

  useEffect(() => {
    setHeaderTitles(buildTableHeaders(weeksToShow));
  }, [weeksToShow]);

  useEffect(() => {
    setTableGrid(buildTableMatrix(weeksToShow, ordersData));
  }, [ordersData, currentPosition, weeksToShow]);

  const handlePointerDown = event => {
    const order_id = event.target.dataset.order_id;

    if (order_id) {
      const type = event.target.dataset.type;
      const param = event.target.dataset.param;

      setActiveJob(order_id);
      setIsDragging(true);
      setDraggingPosition(ordersData[order_id][`${type}_${param}`]);
      setStartingPosition(event.target.getBoundingClientRect().left);
      setTypeName(type);
      setParamName(param);
    }
  };

  const handlePointerMove = event => {
    if (isDragging) {
      const horizontalMove = event.clientX - startingPosition;
      let newPosition = 0;
      if (paramName === 'start') {
        newPosition = draggingPosition + Math.floor(horizontalMove / width);
      } else if (paramName === 'delay') {
        newPosition = draggingPosition + Math.floor((horizontalMove - 20) / width) + 1; // 20 px - is an arbitrary value to give more natural UX on the edges
      }

      if (currentPosition !== newPosition) {
        if (typeName === 'op' && 0 <= newPosition && newPosition <= weeksToShow) {
          setCurrentPosition(newPosition);

          let specials_min = 0;
          if (paramName === 'start') {
            specials_min = newPosition + ordersData[activeJob][`op_delay`] + 1;
          } else if (paramName === 'delay') {
            specials_min = ordersData[activeJob][`op_start`] + newPosition + 1;
          }

          if (ordersData[activeJob].specials_start < specials_min) {
            setOrdersData(prevState => {
              prevState[activeJob][`${typeName}_${paramName}`] = newPosition;
              prevState[activeJob][`specials_start`] = specials_min; // moves start of Specials to avoid overlapping with OP
              return prevState;
            });
          }
          setOrdersData(prevState => {
            prevState[activeJob][`${typeName}_${paramName}`] = newPosition;
            return prevState;
          });
        }

        const specials_min = ordersData[activeJob].op_start + ordersData[activeJob].op_delay + 1;
        if (
          typeName === 'specials' &&
          (specials_min <= newPosition || paramName === 'delay') &&
          newPosition <= weeksToShow
        ) {
          setCurrentPosition(newPosition);
          setOrdersData(prevState => {
            prevState[activeJob][`${typeName}_${paramName}`] = newPosition;
            return prevState;
          });
        }
      }
    }
  };

  const handlePointerUp = event => {
    if (activeJob) {
      setActiveJob();
    }
    setIsDragging(false);
  };

  return (
    <>
      <Card>
        <CardBody>
          <div
            id="container"
            className="text-center"
            style={{ overflowX: 'auto' }}
            onPointerDownCapture={event => handlePointerDown(event)}
            onPointerMoveCapture={event => handlePointerMove(event)}
            onPointerUpCapture={event => handlePointerUp(event)}
          >
            <Table bordered className="text-center" style={{ width: width * (weeksToShow + 2) }}>
              <thead className="thead-light text-center">
                <tr>
                  <th />
                  {headerTitles.map(item => (
                    <th key={item} width={width}>
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableGrid.map((row, i) => (
                  <tr key={row[0]}>
                    <th scope="row">{row[0]}</th>
                    {row.slice(1).map((job, index) => (
                      <JobVisual key={index} job={job} width={width} />
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>

      <Card className="mt-3">
        <CardBody>
          {ordersData.map((line, index) => (
            <Fragment key={index}>
              {JSON.stringify(line)}
              <br />
            </Fragment>
          ))}
        </CardBody>
      </Card>
    </>
  );
};

export default Test;
