import React from 'react';

const JobVisual = ({ job, width }) => {
  return (
    <td className="px-0">
      <div className="bg-300 " style={{ position: 'relative', left: 0, userSelect: 'none' }}>
        {job && (
          <>
            {/* Effort Element */}
            <div
              className={`bg-${job.type === 'op' ? 'primary' : 'success'} text-light`}
              data-order_id={job.order_id}
              data-type={job.type}
              data-param="start"
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: width,
                cursor: 'grab',
                borderRadius: '100px',
                touchAction: 'none'
              }}
            >
              {job.effort_hours}h
            </div>

            {/* Delay Element */}
            {Boolean(job.delay) && (
              <div
                className="bg-300 "
                // data-type={job.type}
                // data-delay={true}
                // data-field="op_delay"
                style={{
                  position: 'absolute',
                  left: width,
                  top: 0,
                  width: width * job.delay,
                  // cursor: 'w-resize',
                  borderRadius: '100px'
                  // touchAction: 'none'
                }}
              >
                {job.delay}w
              </div>
            )}

            {/* Delay Resizing Element */}
            <div
              className="bg-600 "
              data-order_id={job.order_id}
              data-type={job.type}
              data-param="delay"
              style={{
                position: 'absolute',
                left: width * (1 + job.delay) - 5,
                top: 6.5,
                width: 10,
                height: 10,
                cursor: 'w-resize',
                borderRadius: '100px',
                touchAction: 'none',

                zIndex: 10
              }}
            />
          </>
        )}
      </div>
    </td>
  );
};

export default JobVisual;
